import "./src/styles/global.css";

export const shouldUpdateScroll = ({
                                       routerProps: { location },
                                       getSavedScrollPosition,
                                   }) => {
    const savedPosition = getSavedScrollPosition(location);
    const anchor = location.hash ? document.querySelector(location.hash) : null;

    if (anchor) {
        window.scrollTo({
            top: anchor.offsetTop,
            behavior: "smooth",
        });
        return false;
    }

    return savedPosition ? [0, savedPosition] : true;
};